import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { useToggleWebService } from "./dataloader";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Component
import ViewAlarm from "./ViewAlarm";
import R53SetAlarm from "./R53SetAlarm";
import { useSelector } from "react-redux";
import { Authority } from "components/Authority";
import SpinnerLoader from "components/Common/SpinnerLoader";
import ViewAlarmIcon from "components/Common/ViewAlarmIcon";

const initialState = { selectedRowId: "", action: "" };
function reducer(state, action) {
  switch (action.type) {
    case "view_alarm":
      return {
        ...state,
        action: "view_alarm",
        selectedRowId: action.id,
      };
    case "create_alarm":
      return {
        ...state,
        action: "create_alarm",
        selectedRowId: action.id,
      };
    case "clear_action":
      return {
        ...state,
        action: "",
        selectedRowId: "",
      };

    default:
      return state;
  }
}

const WebserviceDatatable = ({
  webserviceData,
  selectedApplicationName,
  apps,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [webServiceArray, setWebServiceDataArray] = useState([]);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { mutateAsync: toggleWebSerice, isLoading } = useToggleWebService(
    selectedApplicationName
  );

  const columns = [
    {
      dataField: "url",
      text: "URL",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return (
          <a
            href={`${row.url}`}
            className="splunk-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.url}
          </a>
        );
      },
    },
    {
      dataField: "type",
      text: "TYPE",
      sort: true,
      searchable: true,
    },
    // {
    //   dataField: 'regions',
    //   text: 'REGIONS',
    //   sort: true,
    //   searchable: true,
    // },
    {
      dataField: "status",
      text: "HEALTH STATUS",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Last_failure_Time",
      text: "LAST FAILURE TIME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "disabled",
      text: "Status",
      formatter: (cellContent, row) => {
        return row.disabled ? <div>Disabled</div> : <div>Enabled</div>;
      },
    },
    {
      dataField: "disabled",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            {row.disabled ? (
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() =>
                  toggleWebSerice({
                    action: "disable",
                    application: selectedApplicationName,
                    url: row.url,
                    idToken: userInfo.idToken,
                  })
                }
              >
                Enable
              </button>
            ) : (
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() =>
                  toggleWebSerice({
                    action: "disable",
                    application: selectedApplicationName,
                    url: row.url,
                    idToken: userInfo.idToken,
                  })
                }
              >
                Disable
              </button>
            )}
            <Authority name={selectedApplicationName} apps={apps}>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "view_alarm", id: row.url });
                }}
              >
                <ViewAlarmIcon />
              </button>
            </Authority>
          </React.Fragment>
        );
      },
    },

    // {
    //   dataField: 'dummyActionField',
    //   text: 'ACTION',
    //   formatter: (cellContent, row) => {
    //     return (
    //       <button type='button' className='teal-nobordered-btn'>
    //         View Metrics
    //       </button>
    //     );
    //   },
    // },
  ];

  const defaultSorted = [
    {
      dataField: "webServiceUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: webServiceArray.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let webServiceArray = [];

    for (var i = 0; i < webserviceData.data.details.length; i++) {
      var webServiceObject = {};
      webServiceObject["webServiceUniqueId"] = i;
      webServiceObject["url"] = webserviceData.data.details[i].url;
      webServiceObject["type"] = webserviceData.data.details[i].type;
      // webServiceObject['regions'] = webserviceData.data.details[i].regions;
      webServiceObject["status"] = webserviceData.data.details[i].status;
      webServiceObject["Last_failure_Time"] =
        webserviceData.data.details[i].Last_failure_Time;
      webServiceObject["disabled"] = webserviceData.data.details[i].disabled;

      webServiceArray.push(webServiceObject);
    }

    setWebServiceDataArray(webServiceArray);
  }, []);
  return (
    <div className="custom-card mb-4">
      {isLoading && <SpinnerLoader />}
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="webServiceUniqueId"
        columns={columns}
        data={webServiceArray}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="webServiceUniqueId"
            columns={columns}
            data={webServiceArray}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={6} className="mb-2">
                    <span className="subtitle-text">
                      Web service / URL check
                    </span>
                  </Col>

                  {webServiceArray.length > 0 && (
                    <Col md={4} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}

                  <Authority name={selectedApplicationName} apps={apps}>
                    <Col md={2} className="mb-2">
                      <button
                        type="button"
                        className="teal-filled-btn w-100"
                        onClick={() => {
                          dispatch({ type: "create_alarm" });
                        }}
                      >
                        <i className="fa fa-plus"></i> Create Health Check
                      </button>
                    </Col>
                  </Authority>
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"webServiceUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {webServiceArray.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {state.action === "view_alarm" && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/Route53"
          closeViewParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}

      {/* Set Alarm */}
      {state.action === "create_alarm" && (
        <R53SetAlarm
          application={selectedApplicationName}
          closeSetAlarmParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}
    </div>
  );
};

export default WebserviceDatatable;
