import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

const ServiceDatatable = ({ serviceData }) => {
  const [serviceDataArray, setserviceDataArray] = useState([]);
  const columns = [
    {
      dataField: "StatusCheckTime",
      text: "STATUS CHECK TIME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "ServerName",
      text: "SERVER NAME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Service",
      text: "SERVICE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Status",
      text: "STATUS",
      sort: true,
      searchable: true,
    },
    // {
    //   dataField: 'dummyActionField',
    //   text: 'ACTION',
    //   formatter: (cellContent, row) => {
    //     return (
    //       <button type='button' className='teal-nobordered-btn'>
    //         View Metrics
    //       </button>
    //     );
    //   },
    // },
  ];

  const defaultSorted = [
    {
      dataField: "ServiceUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: serviceDataArray.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let serviceArray = [];

    for (var i = 0; i < serviceData.data.body.length; i++) {
      var serviceObject = {};
      serviceObject["ServiceUniqueId"] = i;
      serviceObject["StatusCheckTime"] =
        serviceData.data.body[i].StatusCheckTime;
      serviceObject["ServerName"] = serviceData.data.body[i].ServerName;
      serviceObject["Service"] = serviceData.data.body[i].Service;
      serviceObject["Status"] = serviceData.data.body[i].Status;

      serviceArray.push(serviceObject);
    }

    setserviceDataArray(serviceArray);
  }, []);

  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="ServiceUniqueId"
        columns={columns}
        data={serviceDataArray}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="ServiceUniqueId"
            columns={columns}
            data={serviceDataArray}
            // search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={9} className="mb-2">
                    <span className="subtitle-text">Service Details</span>
                  </Col>

                  {serviceDataArray.length > 0 && (
                    <Col md={3}>
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"ServiceUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {serviceData.data.body.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  );
};

export default ServiceDatatable;
