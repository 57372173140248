import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Dropdown, Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Helper
import { postApiCall } from "helper/postApiCall";
import { getApiCall } from "helper/getApiCall";

// Components
import SpinnerLoader from "components/Common/SpinnerLoader";

// Internal
import ViewEditContainer from "../ViewEditContainer";
import AzureView from "./AzureView";

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
  CS_Hub_AWS_Account_Search_Read_Only_Security,
} from "roles/roles";

import filterlisticon from "icons/filter-list-icon.svg";
import iconedit from "icons/icon-edit.svg";
import iconeye from "icons/icon-eye.svg";
import AzureEdit from "./AzureEdit";

import { ACCOUNT_REQUEST_RELOAD } from "redux/account-requests/accountRequestConstant";

const AzureTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const accountRequestReload = useSelector(
    (state) => state.accountRequestReload
  );
  const { accountRequestReloadType } = accountRequestReload;

  // Component State
  const [isSuspendedAccountIncluded, setisSuspendedAccountIncluded] = useState(
    false
  );
  const [opcoValues, setOpcoValues] = useState([]);
  const [filterName, setFilterName] = useState({
    filterPlaceHolder: null,
    filterLabel: null,
    filterValue: "",
  });
  const [viewEditState, setViewEditState] = useState("");
  const [viewEditdata, setViewEditData] = useState("");

  const [dataInputError, setDataInputError] = useState("");

  const closeContainerCallback = useCallback((value) => {
    setViewEditState(value);
  }, []);

  // API State & Variable
  const [apiLoading, setApiLoading] = useState(false);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const apiUrl = `${process.env.REACT_APP_AZURE_ACCOUNT_SEARCH}`;
  const idToken = `${userInfo.idToken}`;

  const fetchTableData = (async) => {
    setApiError(null);

    const requestBody = {
      suspacc: isSuspendedAccountIncluded,
      ...(filterName.filterLabel === "AccName" && {
        AccName: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "AccNum" && {
        AccNum: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "AOemail" && {
        AOemail: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "BCemail" && {
        BCemail: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "TCemail" && {
        TCemail: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "SCemail" && {
        SCemail: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "io" && {
        io: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "costc" && {
        costc: `${filterName.filterValue}`,
      }),
      ...(filterName.filterLabel === "opco" && {
        opco: `${filterName.filterValue}`,
      }),
    };
    let requestBodyObjectLength = Object.keys(requestBody).length;

    if (requestBodyObjectLength > 1) {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setApiResponseData(response.data.body);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
          );
          setApiLoading(false);
        });
    }
  };

  // DataTable Variables, State & Functions
  const columns = [
    {
      dataField: "accountnumber",
      text: t("account_number"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "accountname",
      text: t("account_name"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "accstatus",
      text: t("status"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.accstatus === "ACTIVE" || row.accstatus === "Active") {
          return (
            <div
              className="approved-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.accstatus}
            </div>
          );
        } else if (
          row.accstatus === "SUSPENDED" ||
          row.accstatus === "Suspended"
        ) {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.accstatus}
            </div>
          );
        } else {
          return (
            <div
              className="pending-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.accstatus}
            </div>
          );
        }
      },
    },
    {
      // dataField: 'dummyAccountOwnerName',
      // isDummyField: true,
      dataField: `firstNameAO`,
      text: t("account_owner_name"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return (
          <span>
            {row.firstNameAO} {row.lastNameAO}
          </span>
        );
      },
    },
    {
      dataField: "dummyActionField",
      text: t("action"),
      formatter: (cellContent, row) => {
        return (
          <ul className="action-ul">
            {(userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              userInfo.groups.includes(
                CS_Hub_AWS_Dynamo_Search_Request_Admin
              )) &&
              row.accstatus !== "SUSPENDED" && (
                <></>
                // <li className='custom-pointer'>
                //   <img
                //     src={iconedit}
                //     alt='Edit'
                //     onClick={() => {
                //       setViewEditState('Edit');
                //       setViewEditData(row);
                //     }}
                //   />
                // </li>
              )}
            <li className="custom-pointer">
              <img
                src={iconeye}
                alt="View"
                onClick={() => {
                  setViewEditState("View");
                  setViewEditData(row);
                }}
              />
            </li>
          </ul>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "accountnumber",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterName({
      filterPlaceHolder: filterName.filterPlaceHolder,
      filterLabel: filterName.filterLabel,
      filterValue: e.target.value,
    });
    if (filterName.filterValue === "") {
      setDataInputError(`${filterName.filterPlaceHolder} is required`);
    } else {
      fetchTableData();
    }
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_AZURE_ACCOUNT_SEARCH_OPCOS}`;
    const idToken = userInfo.idToken;
    getApiCall(apiUrl, idToken).then((response) => {
      setOpcoValues(response.data.body);
    });
  }, []);

  useEffect(() => {
    if (accountRequestReloadType === true) {
      setViewEditState("");
      dispatch({
        type: ACCOUNT_REQUEST_RELOAD,
        payload: false,
      });
    }
  }, [accountRequestReloadType, dispatch]);

  return (
    <div>
      {apiLoading === false && apiError !== null && (
        <Alert variant="danger">{apiError}</Alert>
      )}

      {apiLoading && <SpinnerLoader />}
      {apiLoading === false && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="accountnumber"
          columns={columns}
          data={apiResponseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="accountnumber"
              columns={columns}
              data={apiResponseData}
              search
            >
              {(toolkitProps) => (
                <>
                  <Row>
                    <Col md={3} className="mt-3 d-flex align-items-center">
                      <p className="maintitle-text">
                        {t("azure_advance_search")}
                      </p>
                    </Col>

                    {filterName.filterPlaceHolder === null && (
                      <Col md={4} className="mt-3"></Col>
                    )}

                    {apiResponseData.length > 0 && (
                      <Col md={3} className="mt-3">
                        <div className="search-input-group">
                          <i className="fa fa-search"></i>
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder={t("search_placeholder")}
                            className="custom-search-input-form-control"
                          />
                        </div>
                      </Col>
                    )}

                    {apiResponseData.length === 0 && (
                      <Col md={3} className="mt-3"></Col>
                    )}

                    {filterName.filterPlaceHolder !== null && (
                      <Col md={4} className="mt-3">
                        {filterName.filterLabel !== null &&
                          filterName.filterLabel === "opco" && (
                            <>
                              <div className="input-group">
                                <Form.Select
                                  className="custom-select"
                                  value={filterName.filterValue}
                                  isInvalid={
                                    dataInputError !== "" ? true : false
                                  }
                                  onChange={(e) => {
                                    setFilterName({
                                      filterPlaceHolder:
                                        filterName.filterPlaceHolder,
                                      filterLabel: filterName.filterLabel,
                                      filterValue: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">
                                    Select Operating Company
                                  </option>
                                  {opcoValues.map((data, index) => (
                                    <option key={index} value={data.opco}>
                                      {data.opconame}
                                    </option>
                                  ))}
                                </Form.Select>
                                <div className="input-group-append">
                                  <button
                                    className="btn teal-filled-btn"
                                    style={{ borderRadius: "0" }}
                                    type="button"
                                    onClick={() => {
                                      if (filterName.filterValue === "") {
                                        setDataInputError(
                                          `${filterName.filterPlaceHolder} is required`
                                        );
                                      } else {
                                        fetchTableData();
                                      }
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="form-error-message mb-3 m-2">
                                {dataInputError}
                              </div>
                            </>
                          )}
                        {filterName.filterLabel !== null &&
                          filterName.filterLabel !== "opco" && (
                            <form onSubmit={handleSubmit}>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className={
                                    "form-control " +
                                    (dataInputError !== "" ? "is-invalid" : "")
                                  }
                                  value={filterName.filterValue}
                                  placeholder={filterName.filterPlaceHolder}
                                  onChange={(e) => {
                                    setFilterName({
                                      filterPlaceHolder:
                                        filterName.filterPlaceHolder,
                                      filterLabel: filterName.filterLabel,
                                      filterValue: e.target.value,
                                    });
                                  }}
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn teal-filled-btn"
                                    style={{ borderRadius: "0" }}
                                    type="button"
                                    onClick={() => {
                                      if (filterName.filterValue === "") {
                                        setDataInputError(
                                          `${filterName.filterPlaceHolder} is required`
                                        );
                                      } else {
                                        fetchTableData();
                                      }
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="form-error-message mb-3 m-2">
                                {dataInputError}
                              </div>
                            </form>
                          )}
                      </Col>
                    )}

                    <Col md={2} className="mt-3">
                      <Dropdown>
                        <Dropdown.Toggle className="filter-dropdown">
                          {/* <img src={filterlisticon} alt='Filter' /> */}
                          {t("filter")} <i className="fa fa-filter"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>
                            {t("search_by")}
                          </Dropdown.ItemText>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("account_name"),
                                filterLabel: "AccName",
                                filterValue: "",
                              });
                            }}
                          >
                            {t("account_name")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("account_owner_email"),
                                filterLabel: `AOemail`,
                                filterValue: "",
                              });
                            }}
                          >
                            {t("account_owner_email")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("account_number"),
                                filterLabel: "AccNum",
                                filterValue: "",
                              });
                            }}
                          >
                            {t("account_number")}
                          </Dropdown.Item>
                          {(userInfo.groups.includes(
                            CS_Hub_AWS_Account_Request_Admin
                          ) ||
                            userInfo.groups.includes(
                              CS_Hub_AWS_Dynamo_Search_Request_Admin
                            )) && (
                            <>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setApiResponseData([]);
                                  setFilterName({
                                    filterPlaceHolder: t("billing_owner_email"),
                                    filterLabel: `BCemail`,
                                    filterValue: "",
                                  });
                                }}
                              >
                                {t("billing_owner_email")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setApiResponseData([]);
                                  setFilterName({
                                    filterPlaceHolder: t("billing_cost_center"),
                                    filterLabel: "costc",
                                    filterValue: "",
                                  });
                                }}
                              >
                                {t("billing_cost_center")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  setApiResponseData([]);
                                  setFilterName({
                                    filterPlaceHolder: t("billing_io"),
                                    filterLabel: "io",
                                    filterValue: "",
                                  });
                                }}
                              >
                                {t("billing_io")}
                              </Dropdown.Item>
                            </>
                          )}

                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("opearting_company"),
                                filterLabel: "opco",
                                filterValue: "",
                              });
                            }}
                          >
                            {t("opearting_company")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("security_contact_email"),
                                filterLabel: "SCemail",
                                filterValue: "",
                              });
                            }}
                          >
                            {t("security_contact_email")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setApiResponseData([]);
                              setFilterName({
                                filterPlaceHolder: t("technical_owner_email"),
                                filterLabel: "TCemail",
                                filterValue: "",
                              });
                            }}
                          >
                            {t("technical_owner_email")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex align-items-center">
                      <span className="subtitle-text">
                        {t("include_suspended_accts")}
                      </span>
                      <label className="custom-toggle-switch mx-3">
                        <input
                          type="checkbox"
                          checked={isSuspendedAccountIncluded}
                          onChange={(e) => {
                            let getCheckedValue = e.target.checked;
                            setisSuspendedAccountIncluded(getCheckedValue);
                          }}
                        />
                        <span className="custom-toggle-slider round"></span>
                      </label>
                    </Col>
                  </Row>

                  {apiLoading && <SpinnerLoader />}

                  {apiLoading === false && (
                    <>
                      <Row>
                        <Col md={12} xl={12} className="mt-4">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={
                                "custom-table-head custom-pointer"
                              }
                              bodyClasses={"custom-table-body"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                        <Col>
                          {apiResponseData.length === 0 && (
                            <div className="custom-error-message text-center">
                              {t("select_the_filter")}
                              <i className="fa fa-filter"></i>{" "}
                              {t("to_search_account")}
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-3">
                        <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                          <div>
                            <PaginationTotalStandalone {...paginationProps} />
                          </div>
                          <div>
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {viewEditState === "View" && (
        <ViewEditContainer
          title="Azure"
          data={viewEditdata}
          closeContainer={closeContainerCallback}
          viewEditType="View"
          userInfo={userInfo}
          children={<AzureView data={viewEditdata} userInfo={userInfo} />}
        />
      )}

      {viewEditState === "Edit" && (
        <ViewEditContainer
          title="Azure"
          data={viewEditdata}
          closeContainer={closeContainerCallback}
          viewEditType="Edit"
          userInfo={userInfo}
          children={<AzureEdit data={viewEditdata} userInfo={userInfo} />}
        />
      )}
    </div>
  );
};

export default AzureTable;
