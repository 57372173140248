import React, { useEffect, useState, useReducer, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Component
import ViewAlarm from "./ViewAlarm";
import EC2SetAlarm from "./EC2SetAlarm";
import { Authority } from "components/Authority";
import SetAlarmIcon from "components/Common/SetAlarmIcon";
import ViewAlarmIcon from "components/Common/ViewAlarmIcon";
import ViewMetricsIcon from "components/Common/ViewMetricsIcon";
import EC2SetAlarmAll from "./EC2SetAlarmAll";

const initialState = { selectedRowId: "", action: "", platform: "" };
function reducer(state, action) {
  switch (action.type) {
    case "view_alarm":
      return {
        ...state,
        action: "view_alarm",
        selectedRowId: action.id,
      };
    case "create_alarm":
      return {
        ...state,
        action: "create_alarm",
        selectedRowId: action.id,
        platform: action.platform,
        displayName: action.displayName,
      };
    case "clear_action":
      return {
        ...state,
        action: "",
        selectedRowId: "",
        platform: "",
      };

    default:
      return state;
  }
}

const EC2Datatable = ({
  refProp,
  selectedApplicationName,
  resourceHealthData,
  cloudInfrastructureSelectedValue,
  allAppName,
}) => {
  const [resourceHealthArrayData, setResourceHealthArrayData] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSetAlarm, setShowSetAlarm] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowSetAlarm(false);
  }, []);

  // ! Close Set Alarm Callback
  const closeSetAlarmCallback = useCallback((value) => {
    setShowSetAlarm(value);
  }, []);

  const columns = [
    {
      dataField: "InstanceId",
      text: "IDENTIFIER",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        const monitoringStatus = row.monitoringstatus;
        if (monitoringStatus) {
          return (
            <div>
              {/* <span className="info-green" title="Monitored">
                <i class="fa fa-info-circle mr-3" aria-hidden="true"></i>
              </span> */}
              {row.InstanceId}
            </div>
          );
        } else {
          return (
            <div>
              {/* <span className="info-red" title="Not Monitored">
                <i class="fa fa-info-circle mr-3" aria-hidden="true"></i>
              </span> */}
              {row.InstanceId}
            </div>
          );
        }
      },
    },
    {
      dataField: "DisplayName",
      text: "DISPLAY NAME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Status",
      text: "STATUS",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.Status === "running") {
          return (
            <div className="approved-badge text-capitalize">{row.Status}</div>
          );
        } else {
          return <div className="pending-badge">{row.Status}</div>;
        }
      },
    },
    // {
    //   dataField: "AvailablityZone",
    //   text: "AVAILABLITY ZONE",
    //   sort: true,
    //   searchable: true,
    // },
    {
      dataField: "InstanceType",
      text: "INSTANCE TYPE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Platform",
      text: "PLATFORM TYPE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "dummyActionField",
      text: "ACTION",
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            <Link
              to={`/ec2-metrics/${selectedApplicationName}/${row.InstanceId}/${
                row.DisplayName
              }/${row.Platform.replace("/", "-")}`}
            >
              <button type="button" className="teal-nobordered-btn">
                <ViewMetricsIcon />
              </button>
            </Link>
            <Authority name={selectedApplicationName} apps={allAppName}>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "view_alarm", id: row.InstanceId });
                }}
              >
                <ViewAlarmIcon />
              </button>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({
                    type: "create_alarm",
                    id: row.InstanceId,
                    platform: row.Platform,
                    displayName: row.DisplayName,
                  });
                }}
              >
                <SetAlarmIcon />
              </button>
            </Authority>
          </React.Fragment>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "ec2UniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resourceHealthArrayData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let getSelectedValuesObject = resourceHealthData.data.body.find(
      (o) => o.resource === cloudInfrastructureSelectedValue
    );
    let resourceHealthArray = [];

    for (var i = 0; i < getSelectedValuesObject.items.length; i++) {
      var resourceHealthObject = {};
      resourceHealthObject["ec2UniqueId"] = i;
      resourceHealthObject["InstanceId"] =
        getSelectedValuesObject.items[i].InstanceId;
      resourceHealthObject["DisplayName"] =
        getSelectedValuesObject.items[i].DisplayName;
      resourceHealthObject["Status"] = getSelectedValuesObject.items[i].Status;
      resourceHealthObject["AvailablityZone"] =
        getSelectedValuesObject.items[i].AvailablityZone;
      resourceHealthObject["InstanceType"] =
        getSelectedValuesObject.items[i].InstanceType;
      resourceHealthObject["Platform"] =
        getSelectedValuesObject.items[i].Platform;
      resourceHealthObject["monitoringstatus"] =
        getSelectedValuesObject.items[i].monitoringstatus;

      resourceHealthArray.push(resourceHealthObject);
    }

    setResourceHealthArrayData(resourceHealthArray);
  }, [cloudInfrastructureSelectedValue]);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="ec2UniqueId"
        columns={columns}
        data={resourceHealthArrayData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="ec2UniqueId"
            columns={columns}
            data={resourceHealthArrayData}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={7} className="mb-2">
                    <div className="subtitle-text">
                      <span className="text-uppercase">
                        {cloudInfrastructureSelectedValue}{" "}
                      </span>
                      Details
                    </div>
                  </Col>

                  {resourceHealthArrayData.length > 0 && (
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}

                  {resourceHealthArrayData.length > 0 && (
                    <>
                      <Col md={2} className="mb-2">
                        <button
                          type="button"
                          className="teal-filled-btn w-100"
                          onClick={() => setShowSetAlarm(true)}
                        >
                          <i className="fa fa-plus"></i> Set Alarm
                        </button>
                      </Col>
                    </>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"ec2UniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {resourceHealthArrayData.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {state.action === "view_alarm" && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/EC2"
          closeViewParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}

      {/* Set Alarm */}
      {state.action === "create_alarm" && (
        <EC2SetAlarm
          application={selectedApplicationName}
          namespace="AWS/EC2"
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
          platform={state.platform}
          displayName={state.displayName}
        />
      )}

      {/* Set Alarm */}
      {showSetAlarm && (
        <EC2SetAlarmAll
          application={selectedApplicationName}
          namespace="AWS/EC2"
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={closeSetAlarmCallback}
          platform={state.platform}
        />
      )}
    </div>
  );
};

export default EC2Datatable;
