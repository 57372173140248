import React from "react";

const JobSearchSecondChildPrompt = (props) => {
  const options = [
    {
      text: "Provide eligibility for applying job id - 14567950",
      handler: () => props.actionProvider.handleJobSearch("jobEligibility"),
      id: "js1",
    },
    {
      text: "List the Jobs posted for Sony Music in last week",
      handler: () => props.actionProvider.handleJobSearch("jobsLastWeek"),
      id: "js2",
    },
    {
      text: "Provide more description for Job - 14567950",
      handler: () => props.actionProvider.handleJobSearch("moreJobDesc"),
      id: "js3",
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default JobSearchSecondChildPrompt;
