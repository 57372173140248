import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Import Icons
import backicon from "icons/back.svg";

// Helper
import { postApiCall } from "helper/postApiCall";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

// Validator
import { filterPatternValidator } from "./validator";

const FilterPattern = ({ application, instid, closeSideCanvasParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Form State
  const [filterPatternText, setFilterPatternText] = useState("");
  const [instanceId, setInstanceId] = useState("");

  const [showMore, setShowMore] = useState(false);

  // API State
  const [loading, setLoading] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_LOGS_TEST_PATTERN}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {
    application: `${application.trim()}`,
    FilterPattern: filterPatternText,
    kind: "Test",
    instid: instanceId,
  };

  // Test Pattern Hanlder
  function testPatternHandler() {
    const errors = filterPatternValidator({
      filterPatternText,
      instanceId,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          let tempArray = [];
          for (const property in response.data.body) {
            let tempObj = {};
            tempObj["id"] = property;
            tempObj["EventMessage"] = response.data.body[property];
            tempArray.push(tempObj);
          }
          setResponseData(tempArray);
          setLoading(false);
        })
        .catch((err) => {
          setError(
            "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
          );
          setLoading(false);
        });
    }
  }

  const columns = [
    {
      dataField: "EventMessage",
      text: "Event Message",
      sort: true,
      searchable: true,
      formatExtraData: showMore,
      //   formatter: (cellContent, row) => {
      //     //   const =
      //     if (row.EventMessage.length > 20) {
      //       return (
      //         `${row.EventMessage.substring(0, 19)}` + '' + <div>Read more</div>
      //       );
      //     }
      //   },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: responseData.length,
    custom: true,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    return () => {
      setResponseData([]);
    };
  }, []);
  return (
    <div className="view-edit-box-conatiner pb-5" style={{ width: "660px" }}>
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeSideCanvasParent("")}
          />
          <div>
            <h5 className="view-edit-box-title">Filter Pattern</h5>
          </div>
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          <Col md={8}>
            <Form.Group className="mb-3" controlId="textPatternText">
              <Form.Label>Text Pattern</Form.Label>
              <br />
              <Form.Text className="text-muted" style={{ fontSize: "11px" }}>
                {/* Specify the terms or pattern to match in your log events to
                create metrics. */}
                The latest log stream is considered by default
              </Form.Text>
              <Form.Control
                type="text"
                placeholder="Text Pattern"
                value={filterPatternText}
                onChange={(e) => {
                  setFilterPatternText(e.target.value);
                }}
                isInvalid={dataInputError.filterPatternText ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {dataInputError.filterPatternText}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Form.Group className="mb-3" controlId="textPatternText">
              <Form.Label>Instance</Form.Label>
              <Form.Select
                value={instanceId}
                onChange={(e) => {
                  setInstanceId(e.target.value);
                }}
                isInvalid={dataInputError.instanceId ? true : false}
              >
                <option>Select Instance</option>
                {instid.map((data, index) => (
                  <option key={index} value={data.Logs}>
                    {data.Logs}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {dataInputError.instanceId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                testPatternHandler();
              }}
            >
              Test Pattern
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {loading && <SpinnerLoader />}
            {loading === false && error !== null && (
              <DangerAlert message={error} />
            )}
            {loading === false && error === null && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={responseData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={responseData}
                    search
                  >
                    {(toolkitProps) => (
                      <>
                        <Row className="d-flex align-items-center">
                          <Col md={9} className="mb-2 mt-3">
                            <div className="subtitle-text">Test Results</div>
                          </Col>

                          {responseData.length > 0 && (
                            <Col md={3} className="mb-2 mt-3">
                              <div className="search-input-group">
                                <i className="fa fa-search"></i>
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  className="custom-search-input-form-control"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>

                        <>
                          <Row>
                            <Col md={12} xl={12} className="mt-4">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={
                                    "custom-table-head custom-pointer"
                                  }
                                  bodyClasses={"custom-table-body"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                            <Col>
                              {responseData.length === 0 && (
                                <div className="custom-error-message text-center">
                                  No Data to Display
                                </div>
                              )}
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-3">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FilterPattern;
