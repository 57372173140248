import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Component
import ViewAlarm from "./ViewAlarm";
import EFSSetAlarm from "./EFSSetAlarm";
import { useTranslation } from "react-i18next";

const EFSDatatable = ({
  selectedApplicationName,
  resourceHealthData,
  cloudInfrastructureSelectedValue,
}) => {
  const [resourceHealthArrayData, setResourceHealthArrayData] = useState([]);
  const { t } = useTranslation();
  const [showViewAlarm, setShowViewAlarm] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewAlarm(value);
    setShowSetAlarm(false);
  }, []);

  const [showSetAlarm, setShowSetAlarm] = useState(false);

  // ! Close Set Alarm Callback
  const closeSetAlarmCallback = useCallback((value) => {
    setShowSetAlarm(value);
    setShowViewAlarm(false);
  }, []);

  const columns = [
    {
      dataField: "EFS",
      text: "EFS",
      sort: true,
      searchable: true,
    },
    {
      dataField: "EFSName",
      text: "EFS NAME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "State",
      text: "STATE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Size",
      text: "SIZE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "dummyActionField",
      text: "ACTION",
      formatter: (cellContent, row) => {
        return (
          <Link
            to={`/cloudeye-management-user-view/efs-metrics/${selectedApplicationName}/${row.EFS}/${row.EFSName}`}
          >
            <button type="button" className="teal-nobordered-btn">
              View Metrics
            </button>
          </Link>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "efsUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resourceHealthArrayData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let getSelectedValuesObject = resourceHealthData.data.body.find(
      (o) => o.resource === cloudInfrastructureSelectedValue
    );
    let resourceHealthArray = [];

    for (var i = 0; i < getSelectedValuesObject.items.length; i++) {
      var resourceHealthObject = {};
      resourceHealthObject["efsUniqueId"] = i;
      resourceHealthObject["EFS"] = getSelectedValuesObject.items[i].EFS;
      resourceHealthObject["EFSName"] =
        getSelectedValuesObject.items[i].EFSName;
      resourceHealthObject["State"] = getSelectedValuesObject.items[i].State;
      resourceHealthObject["Size"] = getSelectedValuesObject.items[i].Size;

      resourceHealthArray.push(resourceHealthObject);
    }

    setResourceHealthArrayData(resourceHealthArray);
  }, [cloudInfrastructureSelectedValue]);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="efsUniqueId"
        columns={columns}
        data={resourceHealthArrayData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="efsUniqueId"
            columns={columns}
            data={resourceHealthArrayData}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={9} className="mb-2">
                    <div className="subtitle-text">
                      <span className="text-uppercase">
                        {cloudInfrastructureSelectedValue}{" "}
                      </span>
                      Details
                    </div>
                  </Col>

                  {resourceHealthArrayData.length > 0 && (
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_placeholder")}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}

                  {/* {resourceHealthArrayData.length > 0 && (
                    <>
                      <Col md={2} className='mb-2'>
                        <button
                          type='button'
                          className='teal-bordered-btn w-100'
                          onClick={() => {
                            setShowViewAlarm(true);
                          }}
                        >
                          View Alarm
                        </button>
                      </Col>

                      <Col md={2} className='mb-2'>
                        <button
                          type='button'
                          className='teal-filled-btn w-100'
                          onClick={() => setShowSetAlarm(true)}
                        >
                          <i className='fa fa-plus'></i> Set Alarm
                        </button>
                      </Col>
                    </>
                  )} */}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"efsUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {resourceHealthArrayData.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {showViewAlarm && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/EFS"
          closeViewParent={closeViewCallback}
        />
      )}

      {/* Set Alarm */}
      {showSetAlarm && (
        <EFSSetAlarm
          application={selectedApplicationName}
          namespace="AWS/EFS"
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={closeSetAlarmCallback}
        />
      )}
    </div>
  );
};

export default EFSDatatable;
