export const BOT_WINDOW = "BOT_WINDOW";
export const ACCOUNT_LIST = "ACCOUNT_LIST";
export const ACCOUNT_ID_PROMPT = "ACCOUNT_ID_PROMPT";
export const FINANCE_PROMPT = "FINANCE_PROMPT";
export const APPLICATION_TYPE = "APPLICATION_TYPE";
export const FIRST_INPUT_ENTER = "FIRST_INPUT_ENTER";
export const CONFORMITY_ALERT = "CONFORMITY_ALERT";
export const CONFORMITY_RESOLUTION = "CONFORMITY_RESOLUTION";
export const IS_LOADING = "IS_LOADING";

export const JOB_SEARCH_PROMPT = "JOB_SEARCH_PROMPT";
export const ONLY_LIST_THE_JOBS = "ONLY_LIST_THE_JOBS";
export const JOB_DETAILS = "JOB_DETAILS";
