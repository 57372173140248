import React, { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Loader from "../Loader";

const JobFIleUploadComponent = ({ setResumeUploadModal = () => {} }) => {
  const ref = useRef();
  const [fileName, setFileName] = useState("");
  const [step, setStep] = useState(0);
  const [fileUploadErrors, setfileUploadErrors] = useState(null);
  // const [fileToJson, setFileToJson] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isDownloaded, setDownloaded] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [useWorkdayprofile, setUseWorkdayprofile] = useState(false);

  const uploadImage = async (e) => {
    debugger;
    setfileUploadErrors(null);
    setFileName("");
    setFileContent(null);
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ["csv", "pdf"];
    const fileExtension = filePath.split(".").pop();

    if (checkFileSize >= 5120) {
      setfileUploadErrors("File size should be below 5MB");
      ref.current.value = "";
    } else if (!allowedExtensions.includes(fileExtension)) {
      setfileUploadErrors("File type not allowed");
      ref.current.value = "";
    } else {
      setLoading(true);
      //   const isNotValidHeaders = await filterCsvHeader(file);
      setLoading(false);

      setFileName(file.name);
      setFileContent(file);
    }
  };

  const handleUpload = () => {
    setShowSuccessMessage(true);
  };
  return (
    <>
      {isLoading ? (
        <>
          <p>File upload is in progress... It may take a while</p>
          <Loader />
        </>
      ) : null}
      {showSuccessMessage || apiError ? (
        <>
          {`${
            showSuccessMessage
              ? "Your file has been uploaded successfully."
              : apiError
          } `}
          <ul className="btn-ul mb-0">
            <li>
              <button
                type="button"
                className="teal-filled-btn"
                onClick={() => {
                  setResumeUploadModal(false);
                }}
              >
                Ok
              </button>
            </li>
          </ul>
        </>
      ) : null}
      {!isLoading && !showSuccessMessage && (
        <>
          <div className="billing-upload-container" />
          <div className="text-center either-one-selection">
            Please proceed with either one selection
          </div>
          <div
            className="billing-upload-dropdown "
            show={true}
            style={{ right: "-16px", top: "0px" }}
          >
            <div className="input-box-container-chatbot">
              <div className="text-center acceptable-format-text">
                Acceptable format( CSV or PDF )
              </div>
              <div
                as="button"
                className="file-upload-bg"
                onClick={() => setfileUploadErrors(null)}
              >
                <Row>
                  <Col md={12} sm={12} className="p-1 month-selection-radio">
                    <Form.Group
                      controlId="formFile"
                      className=" billing-file-upload-csv"
                    >
                      {/* <Form.Label>{t("file_upload")}</Form.Label> */}
                      {fileName !== "" && (
                        <div className="mb-3 file-name-label">{fileName}</div>
                      )}
                      <div className="custom-upload-wrapper">
                        <button className="custom-upload-button">
                          <i className="fa fa-cloud-upload"></i>
                          Add file
                        </button>
                        <input
                          type="file"
                          ref={ref}
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <Row className="px-3">
                <Col>
                  <Form.Group className="my-3">
                    <Form.Check
                      type="checkbox"
                      label="Use Workday profile"
                      className="checkbox-input upload-checkbox d-flex justify-content-center"
                      onChange={(e) => {
                        setUseWorkdayprofile(!useWorkdayprofile);
                      }}
                      value={useWorkdayprofile}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            {fileUploadErrors && (
              <p className="text-danger mb-0 fs-13">{fileUploadErrors}</p>
            )}
            <ul className="px-0 btn-ul mb-0 justify-content-center billing-upload-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setFileName("");
                    setResumeUploadModal(false);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button
                  className={` teal-filled-btn ${
                    !useWorkdayprofile && fileName === ""
                      ? "disabled-div disabled-bg"
                      : ""
                  } `}
                  type="button"
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  Confirm
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default JobFIleUploadComponent;
