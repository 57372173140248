import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Component
import ViewAlarm from "./ViewAlarm";
import R53SetAlarm from "./R53SetAlarm";

const WebserviceDatatable = ({ webserviceData, selectedApplicationName }) => {
  const [webServiceArray, setWebServiceDataArray] = useState([]);

  const [showViewAlarm, setShowViewAlarm] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewAlarm(value);
    setShowSetAlarm(false);
  }, []);

  const [showSetAlarm, setShowSetAlarm] = useState(false);

  // ! Close Set Alarm Callback
  const closeSetAlarmCallback = useCallback((value) => {
    setShowSetAlarm(value);
    setShowViewAlarm(false);
  }, []);

  const columns = [
    {
      dataField: "url",
      text: "URL",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return (
          <a
            href={`${row.url}`}
            className="splunk-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.url}
          </a>
        );
      },
    },
    {
      dataField: "type",
      text: "TYPE",
      sort: true,
      searchable: true,
    },
    // {
    //   dataField: 'regions',
    //   text: 'REGIONS',
    //   sort: true,
    //   searchable: true,
    // },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Last_failure_Time",
      text: "LAST FAILURE TIME",
      sort: true,
      searchable: true,
    },
    // {
    //   dataField: 'dummyActionField',
    //   text: 'ACTION',
    //   formatter: (cellContent, row) => {
    //     return (
    //       <button type='button' className='teal-nobordered-btn'>
    //         View Metrics
    //       </button>
    //     );
    //   },
    // },
  ];

  const defaultSorted = [
    {
      dataField: "webServiceUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: webServiceArray.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let webServiceArray = [];

    for (var i = 0; i < webserviceData.data.details.length; i++) {
      var webServiceObject = {};
      webServiceObject["webServiceUniqueId"] = i;
      webServiceObject["url"] = webserviceData.data.details[i].url;
      webServiceObject["type"] = webserviceData.data.details[i].type;
      // webServiceObject['regions'] = webserviceData.data.details[i].regions;
      webServiceObject["status"] = webserviceData.data.details[i].status;
      webServiceObject["Last_failure_Time"] =
        webserviceData.data.details[i].Last_failure_Time;

      webServiceArray.push(webServiceObject);
    }

    setWebServiceDataArray(webServiceArray);
  }, []);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="webServiceUniqueId"
        columns={columns}
        data={webServiceArray}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="webServiceUniqueId"
            columns={columns}
            data={webServiceArray}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={5} className="mb-2">
                    <span className="subtitle-text">
                      Web service / URL check
                    </span>
                  </Col>

                  {webServiceArray.length > 0 && (
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}

                  {webServiceArray.length === 0 && <Col md={3}></Col>}

                  <Col md={2} className="mb-2">
                    <button
                      type="button"
                      className="teal-bordered-btn w-100"
                      onClick={() => {
                        setShowViewAlarm(true);
                      }}
                    >
                      View Alarm
                    </button>
                  </Col>

                  <Col md={2} className="mb-2">
                    <button
                      type="button"
                      className="teal-filled-btn w-100"
                      onClick={() => setShowSetAlarm(true)}
                    >
                      <i className="fa fa-plus"></i> Set Alarm
                    </button>
                  </Col>
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"webServiceUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {webServiceArray.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {showViewAlarm && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/Route53"
          closeViewParent={closeViewCallback}
        />
      )}

      {/* Set Alarm */}
      {showSetAlarm && (
        <R53SetAlarm
          application={selectedApplicationName}
          closeSetAlarmParent={closeSetAlarmCallback}
        />
      )}
    </div>
  );
};

export default WebserviceDatatable;
