import React from "react";
import { useDispatch } from "react-redux";
import { JOB_DETAILS, ONLY_LIST_THE_JOBS } from "redux/chatbot/chatBotConstant";

const JobPrompt = (props) => {
  const dispatch = useDispatch();
  const options = [
    {
      text: "Only list the jobs",
      handler: () => {
        props.actionProvider.handleJobSearch("onlyListTheJobs");
        dispatch({
          type: ONLY_LIST_THE_JOBS,
          payload: true,
        });
        dispatch({
          type: JOB_DETAILS,
          payload: false,
        });
      },
      id: "j1",
    },
    {
      text: "Job details",
      handler: () => {
        props.actionProvider.handleJobSearch("jobDetails");
        dispatch({
          type: ONLY_LIST_THE_JOBS,
          payload: false,
        });
        dispatch({
          type: JOB_DETAILS,
          payload: true,
        });
      },
      id: "j2",
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default JobPrompt;
