import React from "react";
import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import OptionsComponent from "../components/Options.js";
import { useSelector } from "react-redux";
import HeaderComponent from "../components/HeaderComponent.js";
import BotIcon from "../components/BotIcon.js";
import FaqPrompt from "../components/customPrompt/FaqPrompt.js";
import SecurityFirstPrompt from "../components/customPrompt/SecurityFirstPrompt.js";
import SecuritySecondPrompt from "../components/customPrompt/SecuritySecondPrompt.js";
import FinancePrompt from "../components/customPrompt/FinancePrompt.js";
import SecurityFirstAnswerPrompt from "../components/customPrompt/SecurityFirstAnswerPrompt.js";
import JobPrompt from "../components/customPrompt/JobPrompt.js";
import JobSearchFirstChildPrompt from "../components/customPrompt/JobSearchFirstChildPrompt.js";
import JobSearchSecondChildPrompt from "../components/customPrompt/JobSearchSecondChildPrompt.js";

const MyUserAvatar = ({ userName }) => {
  return (
    // <div className="user-dp-remover"></div>
    <p className="user-avatar">{userName}</p>
  );
};
const FlightBotAvatar = () => {
  return (
    // <div className="bot-dp-remover" />
    <p className="chat-bot-icon">
      <BotIcon />
    </p>
  );
};
const config = ({ userName }, props) => ({
  initialMessages: [
    createChatBotMessage(<h6>Dear Cloud User, Welcome to Hub Portal!</h6>, {
      widget: "options",
    }),
  ],
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <OptionsComponent {...props} />,
    },
    {
      widgetName: "faqPrompt",
      widgetFunc: (props) => <FaqPrompt {...props} />,
    },
    {
      widgetName: "SecurityFirstPrompt",
      widgetFunc: (props) => <SecurityFirstPrompt {...props} />,
    },
    {
      widgetName: "SecurityFirstAnswerPrompt",
      widgetFunc: (props) => <SecurityFirstAnswerPrompt {...props} />,
    },
    {
      widgetName: "SecuritySecondPrompt",
      widgetFunc: (props) => <SecuritySecondPrompt {...props} />,
    },
    {
      widgetName: "FinancePrompt",
      widgetFunc: (props) => <FinancePrompt {...props} />,
    },
    {
      widgetName: "jobSearchPrompt",
      widgetFunc: (props) => <JobPrompt {...props} />,
    },
    {
      widgetName: "JobSearchFirstChildPrompt",
      widgetFunc: (props) => <JobSearchFirstChildPrompt {...props} />,
    },
    {
      widgetName: "JobSearchSecondChildPrompt",
      widgetFunc: (props) => <JobSearchSecondChildPrompt {...props} />,
    },
  ],
  state: {
    isInputDisabled: false,
  },
  customComponents: {
    header: (props) => <HeaderComponent {...props} />,
    userAvatar: (props) => <MyUserAvatar {...props} userName={userName} />,
    botAvatar: (props) => <FlightBotAvatar {...props} />,
    customPrompt: (props) => <OptionsComponent {...props} />,
  },
});

export default config;
