import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

const ResourceHealthDatatable = ({ resourceHealthData }) => {
  const [resourceHealthArrayData, setResourceHealthArrayData] = useState([]);

  const columns = [
    {
      dataField: "Resource",
      text: "RESOURCE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Identifier",
      text: "IDENTIFIER",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Name/type",
      text: "NAME/TYPE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Status",
      text: "STATUS",
      sort: true,
      searchable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "resourceHealthUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resourceHealthArrayData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let resourceHealthArray = [];

    for (var i = 0; i < resourceHealthData.data.issue_res.length; i++) {
      var resourceHealthObject = {};
      resourceHealthObject["resourceHealthUniqueId"] = i;

      resourceHealthObject["Resource"] = Object.values(
        resourceHealthData.data.issue_res[i]
      )[0];

      resourceHealthObject["Identifier"] = Object.values(
        resourceHealthData.data.issue_res[i]
      )[1];

      resourceHealthObject["Name/type"] = Object.values(
        resourceHealthData.data.issue_res[i]
      )[2];

      resourceHealthObject["Status"] = Object.values(
        resourceHealthData.data.issue_res[i]
      )[3];

      resourceHealthArray.push(resourceHealthObject);
    }

    setResourceHealthArrayData(resourceHealthArray);
  }, []);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="resourceHealthUniqueId"
        columns={columns}
        data={resourceHealthArrayData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="resourceHealthUniqueId"
            columns={columns}
            data={resourceHealthArrayData}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={9} className="mb-2">
                    <span className="subtitle-text">Service Details</span>
                  </Col>

                  {resourceHealthArrayData.length > 0 && (
                    <Col md={3}>
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"resourceHealthUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {resourceHealthArrayData.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  );
};

export default ResourceHealthDatatable;
