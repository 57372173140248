import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import CustomModal from "components/Modal/CustomModal";

// Roles
import { CS_Hub_AWS_Account_Request_Admin, Everyone_Role } from "roles/roles";

// Helper
import { postApiCall } from "helper/postApiCall";

// Import Icon
import dots from "icons/3-dots.svg";

import Approve from "./Approve";
import Cancel from "./Cancel";
import Decline from "./Decline";
import ViewBox from "./ViewBox";

const ViewRequests = () => {
  const { t } = useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API Variables
  const apiUrl = `${process.env.REACT_APP_MANAGEDAD_REQUEST_VIEW}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = "";
  if (userInfo.groups.includes(Everyone_Role)) {
    requestBody = `{"submittedBy":"${userInfo.email}"}`;
  }
  if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
    requestBody = `{}`;
  }

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // Table Callback
  const fetchTableData = useCallback(() => {
    setApiLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setApiResponseData(response.data);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
        setApiLoading(false);
      });
  }, [apiUrl, idToken, requestBody]);

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
  function optionHandler(selectedOptionDataValues, isMenuOpen = null) {
    if (options.showOption || isMenuOpen) {
      setOptions({
        showOption: false,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    }
  }

  // ! View Part
  // ! View Box State & Callback
  const [showViewBox, setShowViewBox] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  // ! Modal Part
  // ! Modal State & Callback
  const [showModalBox, setShowModalBox] = useState(null);

  // ! Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowModalBox(value);
  }, []);

  // !  Modal Yes Click Part & Reload Table Data
  const yesCallback = useCallback(
    (value) => {
      setShowModalBox(value);
      fetchTableData();
    },
    [fetchTableData]
  );

  // Datatable Variables & Options
  const columns = [
    {
      dataField: "dummyCreatedOn",
      isDummyField: true,
      text: t("request_id"),
      sort: true,
      formatter: (cell, row) => {
        return <span className="text-uppercase">{`${row.uid}`}</span>;
      },
    },
    {
      dataField: "primaryContact",
      text: t("primary_contact"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "accountnumber",
      text: t("acct_number"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "region",
      text: t("region"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "cidr",
      text: t("cidr"),
      sort: true,
      searchable: true,
    },
    {
      // dataField: 'dummyCreatedOn',
      // isDummyField: true,
      dataField: "uid",
      text: t("created_on"),
      sort: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(parseInt(row.uid.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(parseInt(a.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        let dateConvertedB = moment(parseInt(b.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        if (order === "asc") {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === "desc") {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: "requestStatus",
      text: t("status"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (
          row.requestStatus.toLowerCase() === "approved" ||
          row.requestStatus.toLowerCase() === "processing"
        ) {
          return (
            <div
              className="approved-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus.toLowerCase() === "pending") {
          return (
            <div
              className="pending-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus.toLowerCase() === "cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus.toLowerCase() === "declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
      },
    },
    {
      dataField: "dummyActionField",
      text: "",
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className="custom-table-option-conatiner"
            tabIndex="0"
            onBlur={() => {
              optionHandler(row, "open");
            }}
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt="action" className="p-2" />

            {options.showOption === true &&
              options.selectedOptionUID === row.uid && (
                <div className="custom-table-option">
                  <ul>
                    {userInfo.groups.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) && row.requestStatus.toLowerCase() === "pending" ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Approve");
                          }}
                        >
                          Approve
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Cancel");
                          }}
                        >
                          Cancel
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Decline");
                          }}
                        >
                          Decline
                        </li>
                      </>
                    ) : userInfo.groups.includes(Everyone_Role) &&
                      row.requestStatus.toLowerCase() === "pending" ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Cancel");
                          }}
                        >
                          Cancel
                        </li>
                      </>
                    ) : row.requestStatus.toLowerCase() === "approved" ||
                      row.requestStatus.toLowerCase() === "processing" ||
                      row.requestStatus.toLowerCase() === "declined" ||
                      row.requestStatus.toLowerCase() === "cancelled" ? (
                      <li
                        onClick={() => {
                          setShowViewBox(true);
                        }}
                      >
                        View
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "uid",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    fetchTableData();
    return () => {
      setApiResponseData([]);
    };
  }, [fetchTableData]);

  return (
    <div>
      {apiLoading && <SpinnerLoader />}

      {apiLoading === false && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="uid"
          columns={columns}
          data={apiResponseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="uid"
              columns={columns}
              data={apiResponseData}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md={5} className="d-flex align-items-center mb-2">
                      <h5 className="page-content-title">
                        {t("managed_ad_request")}
                      </h5>
                    </Col>

                    <Col md={1} className="mb-2">
                      <button
                        className="teal-bordered-btn w-100"
                        type="button"
                        onClick={() => {
                          fetchTableData();
                        }}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </Col>

                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_table")}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-2">
                      <Link to="/managedad-requests-add">
                        <button className="teal-filled-btn w-100">
                          <i className="fa fa-plus mx-2"></i>
                          {t("new_request")}
                        </button>
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12" className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"uid"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {apiLoading === false &&
        apiError === null &&
        apiResponseData.length === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            No Data to Display
          </div>
        )}

      {apiLoading === false && apiError !== null && (
        <DangerAlert message={apiError} />
      )}

      {showViewBox && (
        <ViewBox
          data={options.selectedOptionData}
          closeViewParent={closeViewCallback}
        />
      )}

      {showModalBox === "Approve" && (
        <CustomModal
          showModal={true}
          children={
            <Approve
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {showModalBox === "Cancel" && (
        <CustomModal
          showModal={true}
          children={
            <Cancel
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {showModalBox === "Decline" && (
        <CustomModal
          showModal={true}
          children={
            <Decline
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}
    </div>
  );
};

export default ViewRequests;
