import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

import MenuTabs from "../MenuTabs";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

import ViewAlarm from "./ViewAlarm";
import FilterPattern from "./FilterPattern";
import SetAlarm from "./SetAlarm";

// Helper
import { postApiCall } from "helper/postApiCall";

import { CLOUD_EYE_APPLICATION_NAME } from "redux/cloudeye-user/cloueyeUserConstant";
import { useQueryClient } from "react-query";
import Api from "api/api";
import { useCloudListApplistApi } from "../dataloader";

const Logs = () => {
  const isMounted = useRef(false);
  useCloudListApplistApi();
  const queryClient = useQueryClient();
  const applist = queryClient.getQueryData([Api.cloudEyeAppList]);
  const dispatch = useDispatch();

  // Application Bar State
  const [toggleApplicationBar, setToggleApplicationBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setToggleApplicationBar(true);
    }
  };

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeApplicationName } = cloudEye;

  const [userApplicationDetails, setUserApplicationDetails] = useState([]);
  const [selectedApplicationName, setSelectedApplicationName] = useState("");

  // Action State
  const [showSideCanvas, setShowSideCanvas] = useState("");

  const closeSideCanvasCallback = useCallback((value) => {
    setShowSideCanvas(value);
  }, []);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_LOGS_TABLE}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = { application: selectedApplicationName };

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const columns = [
    {
      dataField: "Logs",
      text: "Logs",
      sort: true,
      searchable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (isMounted.current) {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          let tempArray = [];
          for (const property in response.data.body) {
            let tempObj = {};
            tempObj["id"] = property;
            tempObj["Logs"] = response.data.body[property];
            tempArray.push(tempObj);
          }
          setApiResponseData(tempArray);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(t("failed_to_retrieve_data"));
          setApiLoading(false);
        });
    }
    return () => {
      setApiResponseData([]);
    };
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleApplicationBar]);

  useEffect(() => {
    let userApplicationDetailsArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = "Monitoring";
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst === 7) {
        let temptext = userInfo.groups[i].substring(
          18,
          userInfo.groups[i].length
        );
        userApplicationDetailsArray.push(temptext);
      }
    }
    setUserApplicationDetails(userApplicationDetailsArray);
    setSelectedApplicationName(cloudEyeApplicationName);
    isMounted.current = true;

    if (selectedApplicationName !== "") {
      dispatch({
        type: CLOUD_EYE_APPLICATION_NAME,
        payload: `${selectedApplicationName}`,
      });
    }
  }, []);
  const { t } = useTranslation();

  return (
    <Row>
      {toggleApplicationBar ? (
        <Col md={2} className="px-0 custom-application-bar">
          <div
            className="custom-arrow-icon"
            onClick={() => setToggleApplicationBar(false)}
          >
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="application-name-container">
            <span className="subtitle-text px-1">
              {t("application_details")}
            </span>
            <Form.Group className="mt-3">
              <Form.Select
                value={
                  userApplicationDetails.filter(
                    (item) => item === "selectedApplicationName"
                  )
                    ? selectedApplicationName
                    : ""
                }
                onChange={(e) => {
                  setSelectedApplicationName(e.target.value);
                  setShowSideCanvas("");
                  dispatch({
                    type: CLOUD_EYE_APPLICATION_NAME,
                    payload: `${e.target.value}`,
                  });
                }}
              >
                <option value="">{t("select_an_application")}</option>
                {applist.map((data, index) => (
                  <option key={index} value={data.trim()}>
                    {data.trim()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
      ) : (
        <Col md={1} className="px-0 custom-application-bar">
          <div
            className="custom-arrow-icon"
            onClick={() => setToggleApplicationBar(true)}
          >
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="application-name-container">
            <h5 className="application-bar-text-rotate">
              {t("application_details")}
            </h5>
          </div>
        </Col>
      )}

      <Col md={toggleApplicationBar ? 10 : 11} className="px-3">
        <MenuTabs />
        <Row>
          {selectedApplicationName === "" && (
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center "
            >
              {applist
                ? t("please_select_the_application")
                : "No Application Assigned"}
            </Col>
          )}
          {selectedApplicationName !== "" && (
            <>
              <Col md={12} className="mb-3">
                <div className="custom-card mb-4">
                  {apiLoading && (
                    <Row>
                      <Col>
                        <SpinnerLoader />
                      </Col>
                    </Row>
                  )}

                  {apiLoading && apiError !== null && (
                    <Row>
                      <Col>
                        <DangerAlert message={apiError} />
                      </Col>
                    </Row>
                  )}

                  {apiLoading === false && apiError === null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={apiResponseData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={apiResponseData}
                          search
                        >
                          {(toolkitProps) => (
                            <>
                              <Row className="d-flex align-items-center">
                                <Col md={7} className="mb-2">
                                  <div className="subtitle-text">
                                    Cloudwatch Logs
                                  </div>
                                </Col>

                                {apiResponseData.length > 0 && (
                                  <>
                                    <Col md={3} className="mb-2">
                                      <div className="search-input-group">
                                        <i className="fa fa-search"></i>
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          className="custom-search-input-form-control"
                                          placeholder={t("search_placeholder")}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={2} className="mb-2">
                                      <Dropdown>
                                        <Dropdown.Toggle className="logs-action-dropdown w-100">
                                          {t("actions")}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="logs-action-dropdown-item">
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas("View Alarm");
                                            }}
                                          >
                                            {t("view_alarm")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas(
                                                "Filter Pattern"
                                              );
                                            }}
                                          >
                                            {t("filte_pattern")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas("Set Alarm");
                                            }}
                                          >
                                            {t("set_alarm")}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </>
                                )}
                              </Row>

                              <>
                                <Row>
                                  <Col md={12} xl={12} className="mt-4">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "custom-table-head custom-pointer"
                                        }
                                        bodyClasses={"custom-table-body"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    {apiResponseData.length === 0 && (
                                      <div className="custom-error-message text-center">
                                        {t("no_data_to_display")}
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-3">
                                  <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                                    <div>
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div>
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}

                  {/* View Alarm */}
                  {showSideCanvas === "View Alarm" && (
                    <ViewAlarm
                      application={selectedApplicationName}
                      resource="CWLogGroups"
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}

                  {/* Filter Pattern */}
                  {showSideCanvas === "Filter Pattern" && (
                    <FilterPattern
                      application={selectedApplicationName}
                      instid={apiResponseData}
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}

                  {/* Set Alarm */}
                  {showSideCanvas === "Set Alarm" && (
                    <SetAlarm
                      application={selectedApplicationName}
                      tableData={apiResponseData}
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Logs;
