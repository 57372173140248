import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import download from "../../images/download-icon.png";

const { SearchBar } = Search;

const fileName = `exported-data`;

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (apiData) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export default function Table({
  data,
  columns = [],
  text = "",
  idField = "",
  sizePerPage = 10,
  striped,
  bordered,
  downloadEnabled,
  onRowClick = () => {},
}) {
  const { t } = useTranslation();
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage,
    totalSize: data.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField={idField}
      columns={columns}
      data={data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={idField}
          columns={columns}
          data={data}
          search
        >
          {(toolkitProps) => (
            <>
              <Row className="d-flex justify-content-between align-items-center custom-table-header">
                <Col md={7} className="mb-2">
                  <div className="subtitle-text fw-bold">
                    <span className="text-uppercase"></span>
                    {text}
                  </div>
                </Col>

                {data.length > 0 && (
                  <>
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_placeholder")}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                    {downloadEnabled && (
                      <Col md={2} className="mb-2">
                        <button
                          type="button"
                          className="d-flex align-items-center justify-content-center teal-bordered-btn w-100"
                          onClick={() => {
                            exportToCSV(data);
                          }}
                        >
                          <img
                            src={download}
                            alt="download-table-data"
                            className="mx-2"
                          />
                          Download
                        </button>
                      </Col>
                    )}
                  </>
                )}
              </Row>

              <>
                <Row>
                  <Col md={12} xl={12} className="mt-3">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={{ idField }}
                        responsive
                        bordered={bordered || false}
                        striped={striped || false}
                        // defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={
                          "custom-table-head custom-pointer"
                        }
                        bodyClasses={"custom-table-body"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        rowEvents={{
                          onClick: (e, row, rowIndex) => onRowClick(row),
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    {data.length === 0 && (
                      <div className="custom-error-message text-center">
                        No Data to Display
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-3">
                  <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                    <div>
                      <PaginationTotalStandalone {...paginationProps} />
                    </div>
                    <div>
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </>
            </>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
}
