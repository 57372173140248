import React from "react";

const JobSearchFirstChildPrompt = (props) => {
  const options = [
    {
      text: "List all the Job vacancies in sony music",
      handler: () => props.actionProvider.handleJobSearch("jobsInSonyMusic"),
      id: "jf1",
    },
    {
      text: "List the jobs available in bengaluru",
      handler: () => props.actionProvider.handleJobSearch("jobsInBengaluru"),
      id: "jf2",
    },
    {
      text: "List the jobs for Data science and cloud computing",
      handler: () => props.actionProvider.handleJobSearch("jobsForDSCC"),
      id: "jf3",
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default JobSearchFirstChildPrompt;
