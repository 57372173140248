import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

// Roles
import { CS_Hub_AWS_Account_Request_Admin, Everyone_Role } from "roles/roles";

// Helper
import { postApiCall } from "helper/postApiCall";

const AccountDetails = ({ selectedAccountNumber, selectedAccountName }) => {
  const { t } = useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [accountDetailApiLoading, setAccountDetailApiLoading] = useState(true);
  const [accountDetailApiResponse, setAccountDetailApiResponse] = useState([]);
  const [accountDetailApiError, setAccountDetailApiError] = useState(null);

  const columns = [
    {
      dataField: "accountnumber",
      text: t("account_number"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return (
          <div
            type="button"
            className="okta-view-assignment-datatable-value"
            onClick={() => {
              selectedAccountNumber(row.accountnumber);
              selectedAccountName(row.accountname);
            }}
          >
            {row.accountnumber}
          </div>
        );
      },
    },
    {
      dataField: "accountname",
      text: t("account_name"),
      sort: true,
      searchable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "accountnumber",
      order: "asc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: accountDetailApiResponse.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    const idToken = `${userInfo.idToken}`;

    // Request Body
    let requestBody = "";
    if (userInfo.groups.includes(Everyone_Role)) {
      requestBody = `{"submittedBy":"${userInfo.email}"}`;
    }
    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      requestBody = `{}`;
    }

    const accountDetailApi = `${process.env.REACT_APP_OKTA_VIEW_ASSIGNMENT_ACCOUNT_DETAILS}`;
    const fetchAccountDetails = async () => {
      setAccountDetailApiLoading(true);
      postApiCall(accountDetailApi, idToken, requestBody)
        .then((response) => {
          setAccountDetailApiResponse(response.data);
          setAccountDetailApiLoading(false);
        })
        .catch((err) => {
          setAccountDetailApiError(t("failed_to_retrieve_data"));
          setAccountDetailApiLoading(false);
        });
    };

    fetchAccountDetails();
  }, []);

  const NoteText = () => (
    <>
      <p className="role-note-text text-secondary margin-left-8">
        <span className="font--bold text-dark note-text">{t("Note")}:</span>
        <span>
          {t(
            "Click on the account number, To view the list of current role assignments."
          )}
        </span>
      </p>
    </>
  );

  return (
    <>
      {accountDetailApiLoading && <SpinnerLoader />}

      {accountDetailApiLoading === false && accountDetailApiError !== null && (
        <Row>
          <Col md={12} className="d-flex justify-content-center mt-4">
            <DangerAlert message={accountDetailApiError} />
          </Col>
        </Row>
      )}

      {accountDetailApiLoading === false && (
        <Row>
          <Col>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="accountnumber"
              columns={columns}
              data={accountDetailApiResponse}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="accountnumber"
                  columns={columns}
                  data={accountDetailApiResponse}
                  search
                >
                  {(toolkitProps) => (
                    <>
                      <Row className="d-flex align-items-center">
                        <Col md={9}>
                          <span className="subtitle-text">
                            {t("account_details")}
                          </span>
                        </Col>

                        {accountDetailApiResponse.length > 0 && (
                          <Col md={3}>
                            <div className="search-input-group">
                              <i className="fa fa-search"></i>
                              <SearchBar
                                {...toolkitProps.searchProps}
                                placeholder={t("search_placeholder")}
                                className="custom-search-input-form-control"
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row className="text-left">
                        <Col md={10}>
                          <NoteText />
                        </Col>
                        <Col md={2}></Col>
                      </Row>

                      <>
                        <Row>
                          <Col md={12} xl={12} className="mt-4">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"accountnumber"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={
                                  "custom-table-head custom-pointer"
                                }
                                bodyClasses={"custom-table-body"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                          <Col>
                            {accountDetailApiResponse.length === 0 && (
                              <div className="custom-error-message text-center">
                                {t("no_data_to_display")}
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-3">
                          <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div>
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AccountDetails;
