export const aiRequestValidate = (values) => {
  let errors = {};
  if (!values?.globalId) {
    errors.globalId = "Global ID is required";
  }

  let isValidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values?.email
  );
  if (!values?.email) {
    errors.email = "Email address is required";
  } else if (isValidEmail === false) {
    errors.email = "Email address is invalid";
  }

  if (!values?.cloudService) {
    errors.cloudService = "Cloud Service is required";
  }
  // if (
  //   values?.cloudService &&
  //   values?.haveCloudAccount === "" &&
  //   values?.dontHaveCloudAccount === ""
  // ) {
  //   errors.cloudSelection = "Cloud Selection is required";
  // }

  if (!values?.cloudAccountNumber && !values?.billingOpco) {
    errors.cloudAccountNumber = "Cloud Account Number is required";
  }

  if (!values?.cloudAccountNumber) {
    if (!values?.billingOpco) {
      errors.billingOpco = "Billing Opco is required";
    }
    if (!values?.billingSubOpco) {
      errors.billingSubOpco = "Billing SubOpco is required";
    }
    if (!values?.billingDepartment) {
      errors.billingDepartment = "Billing Department is required";
    }
    if (!values?.billTo) {
      errors.billTo = "Bill To is required";
    }
    if (values?.billTo === "Global IS (GISC)") {
      if (!values?.billingIo) {
        errors.billingIo = "Billing Io is required";
      }
    }
    if (values?.billTo === "GSID") {
      if (!values?.gsid) {
        errors.gsid = "GSID is required";
      }
    }
    if (values?.billTo === "Other Teams") {
      if (!values?.costCenter) {
        errors.costCenter = "Cost Center is required";
      }
    }
    if (!values?.billingRegion) {
      errors.billingRegion = "Billing Region is required";
    }
  }
  if (
    values?.cloudService === "AWS Bedrock" ||
    values?.cloudService === "Azure OpenAI"
  ) {
    if (!values?.llmModel) {
      errors.llmModel = "Model is required";
    }
  }
  if (values?.cloudService === "Amazon Q Developer") {
    if (!values?.programmingLanguage) {
      errors.programmingLanguage = "Programming Language is required";
    }
  }
  if (
    values?.cloudService === "Amazon Q Business" ||
    values?.cloudService === "AWS Bedrock" ||
    values?.cloudService === "Azure OpenAI"
  ) {
    if (!values?.applicationName) {
      errors.applicationName = "Application Name is required";
    }
    if (!values?.region) {
      errors.region = "Region is required";
    }
    if (!values?.useCaseDescription) {
      errors.useCaseDescription = "Use Case Description is required";
    }

    if (!values?.scopeOfUsers) {
      errors.scopeOfUsers = "Scope Of Users is required";
    }
    if (!values?.typeOfDataProcessed) {
      errors.typeOfDataProcessed = "Type Of Data is required";
    }
    if (!values?.typeOfTasks) {
      errors.typeOfTasks = "Type Of Tasks is required";
    }
    if (
      values?.typeOfTasks === "Other (please specify)" &&
      !values?.typeOfTasks_others
    ) {
      errors.typeOfTasks_others = "Others is required";
    }

    if (!values?.additionalComments) {
      errors.additionalComments = "Additional Comments is required";
    }

    if (!values?.aiUsageType) {
      errors.aiUsageType = "AI Usage Type is required";
    }
    if (!values?.toolsDistribution) {
      errors.toolsDistribution = "Tools Distribution is required";
    }
    if (!values?.businessJustification) {
      errors.businessJustification = "Business Justification is required";
    }
    if (!values?.qualityControlConsent) {
      errors.qualityControlConsent = "Quality ControlConsent is required";
    }
    if (!values?.applicationType) {
      errors.applicationType = "AI Feature is required";
    }
    if (!values?.typeOfEnvironment) {
      errors.typeOfEnvironment = "Type Of Environment is required";
    }
    if (!values?.dataTypeUsedForPOC) {
      errors.dataTypeUsedForPOC = "Data Type Used For POC is required";
    }
  }

  return errors;
};
