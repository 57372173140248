import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import backicon from 'icons/back.svg';

import SpinnerLoader from 'components/Common/SpinnerLoader';

import Pagination from 'components/Pagination/Pagination';

// Import Component
import { REMEDY_TICKET } from '../../redux/dashboard/dashboardConstant';

const RemedyTicketCanvas = ({ title, startDate, endDate, userInfo }) => {
  const dispatch = useDispatch();

  const [downloadLoading, setDownloadLoading] = useState(false);

  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  function hideCanvas() {
    dispatch({
      type: REMEDY_TICKET,
      payload: false,
    });
  }

  // API Variables
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  // Pagination Part
  let PageSize = 6;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchRemedyData = useCallback(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };

    let titleConverted = '';
    if (title === 'Incident') {
      titleConverted = 'Incident';
    }
    if (title === 'Work Order') {
      titleConverted = 'WorkOrder';
    }

    axios
      .post(
        `${process.env.REACT_APP_REMEDY_TICKET_DETAILS}`,
        {
          Assigned_group: 'Global Cloud and Hosting Tools',
          StartDate: startDate,
          EndDate: endDate,
          type: titleConverted,
        },
        config
      )
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      });
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, responseData, PageSize]);

  const fileName = `${title} Details`;

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  function exportToExcelData() {
    setDownloadLoading(true);
    exportToCSV(responseData, fileName);
    setDownloadLoading(false);
  }

  useEffect(() => {
    fetchRemedyData();
    return () => {
      setResponseData([]);
    };
  }, [fetchRemedyData]);

  return (
    <div className='offcanvas-details-wrapper pb-5'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <div className='offcanvas-details-top-bar'>
            <div className='offcanvas-details-back-heading'>
              <img
                src={backicon}
                alt='Back'
                className='view-edit-box-back-btn'
                onClick={() => hideCanvas()}
              />
              <h5 className='view-edit-box-title d-flex align-items-center'>
                {title}
              </h5>
            </div>
            {downloadLoading && <SpinnerLoader />}
            {downloadLoading === false && (
              <button
                type='button'
                className='d-flex align-items-center teal-bordered-btn'
                onClick={() => {
                  exportToExcelData();
                }}
              >
                <i className='bx bxs-download mx-1'></i>
                Download
              </button>
            )}
          </div>

          {/* Table Records */}

          <Table
            responsive
            className=' mb-0 table-centered table-nowrap table-borderless'
          >
            <thead className='offcanvas-details-table-head-font'>
              <tr className='mb-2'>
                <td>INCIDENT</td>
                <td>SUMMARY</td>
                <td>CREATED ON</td>
                <td>ASIGNEE</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody className='offcanvas-details-table-body-font'>
              {currentTableData.map((data, index) => {
                return (
                  <tr key={index} className='mb-3'>
                    <td>{data.incidentid}</td>
                    <td>{data.Summary}</td>
                    <td>{data.Reported_Date}</td>
                    <td>{data.Assignee}</td>
                    <td>
                      {data.Status === 'Closed' && (
                        <div
                          className='declined-badge text-capitalize'
                          style={{ fontSize: '11px' }}
                        >
                          {data.Status}
                        </div>
                      )}
                      {data.Status === 'Resolved' && (
                        <div
                          className='approved-badge text-capitalize'
                          style={{ fontSize: '11px' }}
                        >
                          {data.Status}
                        </div>
                      )}
                      {data.Status === 'Cancelled' && (
                        <div
                          className='cancelled-badge text-capitalize'
                          style={{ fontSize: '11px' }}
                        >
                          {data.Status}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className='d-flex justify-content-end align-items-baseline'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={responseData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              currentTableDataLength={currentTableData.length}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RemedyTicketCanvas;
