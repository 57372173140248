import React, { useEffect, useState, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Component
import ViewAlarm from "./ViewAlarm";
import ApiGatewaySetAlarm from "./ApiGatewaySetAlarm";
import { Authority } from "components/Authority";
import ViewMetricsIcon from "components/Common/ViewMetricsIcon";
import ViewAlarmIcon from "components/Common/ViewAlarmIcon";
import SetAlarmIcon from "components/Common/SetAlarmIcon";
import { useTranslation } from "react-i18next";

const initialState = { selectedRowId: "", action: "" };
function reducer(state, action) {
  switch (action.type) {
    case "view_alarm":
      return {
        ...state,
        action: "view_alarm",
        selectedRowId: action.id,
      };
    case "create_alarm":
      return {
        ...state,
        action: "create_alarm",
        selectedRowId: action.id,
      };
    case "clear_action":
      return {
        ...state,
        action: "",
        selectedRowId: "",
      };

    default:
      return state;
  }
}

const ApiGatewayDatatable = ({
  selectedApplicationName,
  resourceHealthData,
  cloudInfrastructureSelectedValue,
  allAppName,
}) => {
  const [resourceHealthArrayData, setResourceHealthArrayData] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const columns = [
    {
      dataField: "apiid",
      text: "API ID",
      sort: true,
      searchable: true,
    },
    {
      dataField: "apiname",
      text: "API NAME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "endpointConfiguration",
      text: "ENDPOINT CONFIGURATION",
      sort: true,
      searchable: true,
    },

    {
      dataField: "dummyActionField",
      text: "ACTION",
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            <Link
              to={`/apigateway-metrics/${selectedApplicationName}/${row.apiid}/${row.apiname}`}
            >
              <button type="button" className="teal-nobordered-btn">
                <ViewMetricsIcon />
              </button>
            </Link>
            <Authority name={selectedApplicationName} apps={allAppName}>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "view_alarm", id: row.apiid });
                }}
              >
                <ViewAlarmIcon />
              </button>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "create_alarm", id: row.apiid });
                }}
              >
                <SetAlarmIcon />
              </button>
            </Authority>
          </React.Fragment>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "apiUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resourceHealthArrayData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let getSelectedValuesObject = resourceHealthData.data.body.find(
      (o) => o.resource === cloudInfrastructureSelectedValue
    );
    let resourceHealthArray = [];

    for (var i = 0; i < getSelectedValuesObject.items.length; i++) {
      var resourceHealthObject = {};
      resourceHealthObject["apiUniqueId"] = i;
      resourceHealthObject["apiid"] = getSelectedValuesObject.items[i].apiid;
      resourceHealthObject["apiname"] =
        getSelectedValuesObject.items[i].apiname;
      resourceHealthObject["endpointConfiguration"] =
        getSelectedValuesObject.items[i].endpointConfiguration;

      resourceHealthArray.push(resourceHealthObject);
    }

    setResourceHealthArrayData(resourceHealthArray);
  }, [cloudInfrastructureSelectedValue]);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="apiUniqueId"
        columns={columns}
        data={resourceHealthArrayData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="apiUniqueId"
            columns={columns}
            data={resourceHealthArrayData}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={5} className="mb-2">
                    <div className="subtitle-text">
                      <span className="text-uppercase">
                        {cloudInfrastructureSelectedValue}{" "}
                      </span>
                      Details
                    </div>
                  </Col>

                  {resourceHealthArrayData.length > 0 && (
                    <>
                      <Col md={3} className="mb-2">
                        <div className="search-input-group">
                          <i className="fa fa-search"></i>
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder={t("search_placeholder")}
                            className="custom-search-input-form-control"
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"apiUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {resourceHealthArrayData.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {state.action === "view_alarm" && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/APIGATEWAY"
          closeViewParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}

      {/* Set Alarm */}
      {state.action === "create_alarm" && (
        <ApiGatewaySetAlarm
          application={selectedApplicationName}
          namespace="AWS/APIGATEWAY"
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}
    </div>
  );
};

export default ApiGatewayDatatable;
