import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCOUNT_ID_PROMPT,
  APPLICATION_TYPE,
  CONFORMITY_ALERT,
  CONFORMITY_RESOLUTION,
  FINANCE_PROMPT,
  JOB_SEARCH_PROMPT,
} from "redux/chatbot/chatBotConstant";
import BotIcon from "./BotIcon";

const Options = (props) => {
  const dispatch = useDispatch();
  const applicationType = useSelector((state) => state.chatBot.applicationType);
  const hasFirstEnter = useSelector((state) => state.chatBot.isFirstText);

  const faqClick = () => {
    dispatch({
      type: APPLICATION_TYPE,
      payload: "FAQ",
    });
    dispatch({
      type: ACCOUNT_ID_PROMPT,
      payload: false,
    });
    dispatch({
      type: FINANCE_PROMPT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_ALERT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_RESOLUTION,
      payload: false,
    });
  };
  const financeClick = () => {
    dispatch({
      type: APPLICATION_TYPE,
      payload: "Finance",
    });
    dispatch({
      type: CONFORMITY_ALERT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_RESOLUTION,
      payload: false,
    });
  };

  const securityClick = () => {
    dispatch({
      type: APPLICATION_TYPE,
      payload: "Security",
    });
    dispatch({
      type: FINANCE_PROMPT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_ALERT,
      payload: true,
    });
    dispatch({
      type: CONFORMITY_RESOLUTION,
      payload: false,
    });
  };

  const jobSearchClick = () => {
    dispatch({
      type: APPLICATION_TYPE,
      payload: "JobSearch",
    });

    dispatch({
      type: JOB_SEARCH_PROMPT,
      payload: false,
    });
    dispatch({
      type: ACCOUNT_ID_PROMPT,
      payload: false,
    });
    dispatch({
      type: FINANCE_PROMPT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_ALERT,
      payload: false,
    });
    dispatch({
      type: CONFORMITY_RESOLUTION,
      payload: false,
    });
  };

  const options = [
    {
      text: "JobSearch",
      handler: () => {
        // props.actionProvider.handleConfirmity();
        props.actionProvider.handleJobSearchInfo();

        jobSearchClick();
      },
      id: 4,
    },
    // {
    //   text: "Security",
    //   handler: () => {
    //     // props.actionProvider.handleConfirmity();
    //     props.actionProvider.handleAccountInfo();

    //     securityClick();
    //   },
    //   id: 1,
    // },
    // {
    //   text: "FAQ",
    //   handler: () => {
    //     props.actionProvider.handleHubDocuments();
    //     faqClick();
    //   },
    //   id: 2,
    // },
    // {
    //   text: "Finance",
    //   handler: () => {
    //     props.actionProvider.handleFinance();
    //     financeClick();
    //   },
    //   id: 3,
    // },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className={`option-button intial-prompt ${
          option.text === applicationType ? "prompt-highlighter" : ""
        } ${
          applicationType || hasFirstEnter ? "" : "initial-prompt-bg-remover"
        }`}
      >
        {option.text === "JobSearch" ? "Job Search" : option.text}
      </button>
    );
  });

  return (
    <>
      <BotIcon type="topSection" />
      <p className="ask-me-anything">
        I’m Neo, your dedicated assistant for today. Whether you have questions
        about your Cloud Account or need guidance on GCS policies, I’m here to
        assist. To get started, simply select from the options below, and let’s
        dive into your queries together!
      </p>
      <div
        className={`container-position ${
          applicationType || hasFirstEnter ? "main-option-position" : ""
        }`}
      >
        <div className="options-container intial-prompt-container">
          {optionsMarkup}
        </div>
      </div>
    </>
  );
};

export default Options;
